import React, { Component, useEffect, useState } from 'react';

import Table from '../common/table';

import _ from 'lodash';
import { toast } from 'react-toastify';
import Select from '../common/select';

import { getUsers, saveUsers } from '../../services/userService.js';
import { getMessages } from '../../services/messageService.js';
import { addNotification } from '../../services/notificationService';

import Icon from '../common/icon.jsx';

class UserList extends Component {
  state = { users: [], sortColumn: { path: 'created', order: 'desc' }, hasChanges: false };

  async componentDidMount() {
    const { data: users } = await getUsers();
    this.setState({ users });
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  onSelectChange = (event, _id) => {
    const value = event.target.value;
    let temp = [...this.state.users];

    for (let i = 0; i < temp.length; i++) {
      let user = temp[i];

      if (user._id === _id) {
        if (value === 'Admin') {
          user.isAdmin = true;
        } else if (value === 'User') {
          user.isAdmin = false;
        }
        temp[i] = user;
        temp[i].changed = true;
      }
    }
    this.setState({ users: temp, hasChanges: true });
  };

  getUserRole(user) {
    if (user.isAdmin) {
      return 'Admin';
    }
    return 'User';
  }

  save = async () => {
    const { users } = this.state;
    const changed = users.filter((u) => u.changed);
    const newUsers = changed.map(({ changed, ...keepAttrs }) => keepAttrs);
    try {
      const { data: result } = await saveUsers(newUsers);
      toast.success(`${result['updatedusers']} user(s) were succesfully updated.`);
    } catch (ex) {
      if (ex.response && ex.response.status === 403) {
        toast.error(`Access denied.`);
      } else {
        toast.error(`An error occurred while updating the users.`);
      }
    }
  };

  handleCheckboxChange(event, userId, keyName) {
    console.log(userId);
    let temp = [...this.state.users];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]._id === userId) {
        temp[i][keyName] = event.target.checked;
        temp[i].changed = true;
        break;
      }
    }
    this.setState({ users: temp, hasChanges: true });
  }

  render() {
    const { users, sortColumn, changedUsers } = this.state;
    const roles = ['User', 'Admin'];
    const sorted = _.orderBy(users, [sortColumn.path], [sortColumn.order]);

    const columns = [
      {
        path: 'name',
        label: 'Name',
        content: (user) => {
          return <a href={`/profile/${user._id}`}>{user.name}</a>;
        },
      },
      { path: 'email', label: 'E-mail' },
      {
        path: 'verified',
        label: 'Email verified',
        content: (user) => (
          <input
            type='checkbox'
            checked={user.verified}
            readOnly
            title={`Email van ${user.name} is ${user.verified ? '' : 'niet'} geverifieerd`}
            onClick={() => {
              toast.info(
                `Dit veld kan niet worden gewijzigd. Emailadressen worden door gebruikers geverifieerd.`,
              );
            }}
          />
        ),
      },
      {
        path: 'allowAccess',
        label: 'Access',
        content: (user) => (
          <input
            type='checkbox'
            checked={user.allowAccess}
            title={`Geef ${user.name} toegang tot de algoritmenlijst`}
            onChange={(e) => this.handleCheckboxChange(e, user._id, 'allowAccess')}
          />
        ),
      },
      {
        path: 'isAdmin',
        label: 'Rol',
        content: (user) => (
          <Select
            defaultValue={this.getUserRole(user)}
            onChange={(e) => this.onSelectChange(e, user._id)}
            options={roles}
            title={`Verander rol van gebruikers.`}
          ></Select>
        ),
      },
      {
        path: 'created',
        label: 'Registered',
        content: (user) => (
          <span className='txt-small'>{new Date(user.created).toLocaleDateString()}</span>
        ),
      },
    ];

    return (
      <div>
        <Table columns={columns} data={sorted} sortColumn={sortColumn} onSort={this.handleSort} />
        {this.state.hasChanges && (
          <div className='btn btn-primary' onClick={this.save}>
            Save
          </div>
        )}
      </div>
    );
  }
}

const Card = (props) => {
  return (
    <div className={`col-sm-${props.width}`}>
      <div className='mb-3'>
        <div className='card h-100'>
          <div className='card-body'>
            <h6 className='d-flex align-items-center mb-3'>
              <i className='material-icons text-info mr-2'>{props.titlea}</i>
              {props.title}
            </h6>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

const MessageBlock = ({ _id, name, email, message, timestamp }) => {
  return (
    <div className='card p-2 my-2' key={_id}>
      <div className='d-flex'>
        <div className=''>Name: {name}</div>
        <div className='mx-auto'>On: {new Date(timestamp).toLocaleString()}</div>
      </div>
      <div className=''>Email: {email}</div>
      <div className=''>Message: {message}</div>
    </div>
  );
};

const NotificationAdder = () => {
  const [isOpen, setIsOpen] = useState(true); // Manage open/close state
  const [paragraphs, setParagraphs] = useState(['']); // Manage open/close state
  const [style, setStyle] = useState('info');

  const toggleNotification = () => {
    setIsOpen(!isOpen);
  };

  const addParagraph = () => {
    setParagraphs((p) => [...p, '']);
  };

  const handleChange = (index, event) => {
    const temp = [...paragraphs];
    temp[index] = event.target.value;
    setParagraphs(temp);
  };

  const handleStyleChange = (event) => {
    setStyle(event.target.value);
  };

  const submitPost = async () => {
    try {
      await addNotification({ paragraphs, style });
      toast.success('Mededeling toegevoegd!');
    } catch (e) {
      console.log(e);
      toast.error('Er is iets misgegaan bij het opslaan.');
    }
  };

  return (
    <div
      className={`notification-box rounded border border-dark p-4 ${
        isOpen ? 'bg-info text-light' : 'bg-info text-dark'
      }`}
    >
      <div className='d-flex justify-content-between' onClick={toggleNotification}>
        <div className='h3 my-2'>Mededeling toevoegen</div>
        <div className='float-right'>
          {isOpen ? <Icon name='caret-up' /> : <Icon name='caret-down' />}
        </div>
      </div>
      <div>
        {isOpen &&
          paragraphs.map((p, i) => {
            return (
              <div key={i}>
                <label>{`Paragraaf: ${i + 1}`}</label>
                <textarea
                  placeholder='Bericht..'
                  className='form-control'
                  label='Bericht'
                  name='message'
                  rows='5'
                  onChange={(e) => handleChange(i, e)}
                ></textarea>
              </div>
            );
          })}
        <div className='form-group my-2'>
          <label htmlFor='styleSelect'>Kies Stijl van mededeling</label>
          <select
            className='form-control'
            id='styleSelect'
            value={style}
            onChange={handleStyleChange}
          >
            <option value='primary' className='bg-primary text-white'>
              Primary
            </option>
            <option value='secondary' className='bg-secondary text-white'>
              Secondary
            </option>
            <option value='success' className='bg-success text-white'>
              Success
            </option>
            <option value='danger' className='bg-danger text-white'>
              Danger
            </option>
            <option value='warning' className='bg-warning text-dark'>
              Warning
            </option>
            <option value='info' className='bg-info text-white'>
              Info
            </option>
            <option value='light' className='bg-light text-dark'>
              Light
            </option>
            <option value='dark' className='bg-dark text-white'>
              Dark
            </option>
          </select>
        </div>
      </div>
      <div className='btn btn-primary my-3 d-block' onClick={addParagraph}>
        Voeg paragraaf toe
      </div>
      <div className='btn btn-danger my-3' onClick={submitPost}>
        Verzenden
      </div>
    </div>
  );
};

const Admin = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchmessages = async () => {
      const { data: messages } = await getMessages();
      setMessages(messages);
    };
    fetchmessages();
  }, []);

  return (
    <div className='container'>
      <h1>Admin page</h1>
      <NotificationAdder />
      <div className='row my-5'>
        <Card titlea='' title='User list'>
          <UserList />
        </Card>
      </div>

      <div>
        <div className='h3'>Messages</div>
        <div>
          {messages.map((m) => {
            return MessageBlock(m);
          })}
        </div>
      </div>
    </div>
  );
};

export default Admin;

Card.defaultProps = {
  titlea: 'My',
  width: 12,
};
